import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route  } from "react-router-dom";
import { datadogRum } from '@datadog/browser-rum';
import Policy from './pages/policy';
import NotFound from './pages/not-found';
import './index.css';

if (import.meta.env.PROD) {
  datadogRum.init({
      applicationId: 'd53addd8-26c4-4953-859e-6b99120905a8',
      clientToken: 'pub6fb03e2e27774c5a71ffd6e663ab8e75',
      site: 'datadoghq.com',
      service:'policy-viewer',
      env:'prod',
      version: import.meta.env.VITE_APP_VERSION,
      sessionSampleRate:100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel:'mask-user-input',
      allowedTracingUrls: [(url) => url.startsWith("https://api.verticalinsure.com")],
      beforeSend: (event, context) => {
        // Seem to be getting errors related to outlook looking through links?
        // See https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-865857552
        if (event.type === 'error' && event.error.message.includes('Object Not Found Matching Id')) {
          return false
        }
      }
  });
      
  datadogRum.startSessionReplayRecording();
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index path="/:policyNumber" element={<Policy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);