import { Button } from "@/components/ui/button";

export default function NotFound() {
  return (
    <>
      <nav className="flex flex-col sm:flex-row items-center justify-between px-6 py-2">
        <div className="flex-1 flex mb-2 md:mb-0 items-center justify-between md:justify-start w-full">
          <img className="w-40 md:w-48" src="https://brand.verticalinsure.com/assets/201_Vertical%20Insure-identity-02.png" />
        </div>
        <div className="flex">
          <Button asChild variant="link">
            <a href="https://www.verticalinsure.com/support" target="_blank">Contact Support</a>
          </Button>
          <Button asChild variant="link">
            <a href="https://customers.verticalinsure.com" target="_blank">My Account</a>
          </Button>
        </div>
      </nav>
      <div className="h-full w-full bg-primary-foreground flex flex-col flex-1 items-center justify-center p-8 space-y-4">
          <h1 className="font-sans text-3xl sm:text-5xl text-primary font-bold">404</h1>
          <h2 className="font-serif text-xl sm:text-3xl text-primary">The page you are looking for could not be found.</h2>
      </div>
    </>
  );
}