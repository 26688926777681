import { useParams, useSearchParams } from "react-router-dom";
import * as jose from 'jose'
import { useState } from "react";
import useSWR from 'swr';
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { PDFObject } from 'react-pdfobject'
import { Form, FormField, FormItem, FormLabel, FormControl, FormDescription, FormMessage } from '@/components/ui/form';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { AlertTriangle, FileIcon, Loader2, MailIcon, MenuIcon, PhoneIcon } from 'lucide-react';
import NotFound from "./not-found";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {camelCase, startCase} from "lodash";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer";

const formSchema = z.object({
  email_address: z.string().email()
})

const getIdTokenClaims = (idToken: string) => {
  return jose.decodeJwt(idToken);
}

const tokenIsExpired = (claims: jose.JWTPayload) => {
  const currentUTCTime = new Date().getTime();
  const toMs = 1000;
  return claims.exp! * toMs < currentUTCTime;
};

export default function Policy() {
  const [newLinkSent, setNewLinkSent] = useState(false);
  const [newLinkError, setNewLinkError] = useState(false);
  const [formLoading, setFormLoading] = useState(false); 
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(0);
  const [documentMenuOpen, setDocumentMenuOpen] = useState(false);
  
  const params = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email_address: "",
    },
  })

  function onSubmit(values: z.infer<typeof formSchema>) {
    setFormLoading(true);
    fetch(`${import.meta.env.VITE_API_URL}/v1/policies/${params.policyNumber}/send-document-email`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${id}`
      },
      body: JSON.stringify({
        email_address: values.email_address
      })
    }).then((response) => {
      if (response.status === 200) {
        setNewLinkSent(true);
      } else {
        setNewLinkError(true);
      }
      setFormLoading(false);
    })
  }

  const { data: carrierData, error: carrierDataError, isLoading: carrierDataLoading } = useSWR(() => id || id !== '' 
    ? `${import.meta.env.VITE_API_URL}/v1/carrier/${params.policyNumber}?id=${id}` 
    : null, async (url: string) => {
    const response = await fetch(url, { cache: 'no-store' });
    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        throw new Error("BadAuthentication");
      } else if (response.status === 404) {
        throw new Error("PolicyNotFound");
      } else {
        throw new Error("UnknownError");
      }
    }
    return await response.json();
  }, {
    shouldRetryOnError: false,
    revalidateOnFocus: false
  });

  try {
    const tokenClaims = getIdTokenClaims(id!);
    const isTokenExpired = tokenIsExpired(tokenClaims);

    if (!id || id === '') {
      return NotFound();
    }

    if (carrierDataLoading) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <Loader2 className="animate-spin text-primary h-10 w-10" />
        </div>
      );
    }

    if (carrierDataError && carrierDataError.message === 'PolicyNotFound') {
      return NotFound();
    }

    const claimsButton = () => {
      if (!carrierDataError && !isTokenExpired) {
        return (
          <Dialog>
            <DialogTrigger asChild>
              <Button className="rounded-full" variant="secondary">File Claim</Button>
            </DialogTrigger>
            <DialogContent className={`w-[95%] md:w-auto max-w-[600px]`}>
              <DialogHeader>
                <DialogTitle>File a Claim</DialogTitle>
              </DialogHeader>
              <div>
                <h3 className="scroll-m-20 text-2xl font-semibold tracking-tight">
                  {carrierData.carrier_name}
                </h3>
                {carrierData.claims_url && 
                  <p>
                    To make a claim online or find out more information about the claims process, <a href={carrierData.claims_url} target="_blank" className="text-secondary underline">click here</a>.
                  </p>
                }
                {carrierData.claims_email && 
                  <div className="mt-4 flex">
                    <MailIcon className='h-6 w-6 mr-2'/> <a href={`mailto:${carrierData.claims_email}`} className="underline">{carrierData.claims_email}</a>
                  </div>
                }
                {carrierData.claims_phone && 
                  <div className="mt-4 flex">
                    <PhoneIcon className='h-6 w-6 mr-2'/> <a href={`tel:${carrierData.claims_phone}`} className="underline">{carrierData.claims_phone}</a>
                  </div>
                }
              </div>
            </DialogContent>
          </Dialog>
        )
      }
    }

    const documentMenu = () => (
      <ul>
        {(tokenClaims.resource_location as any[]).map((r, i) => (
          <li className={`${selectedDocumentIndex == i ? 'bg-primary/10' : ''} border-b px-3 py-2 hover:bg-primary/5 cursor-pointer`} onClick={() => {setDocumentMenuOpen(false); setSelectedDocumentIndex(i);}}>
            <div className="flex items-center text-sm font-sans"><FileIcon className="h-4 w-4 mr-2"/>{startCase(camelCase(r.type))}</div>
          </li>
        ))}
      </ul>
    )
    
    return (
      <>
      <nav className="flex flex-col sm:flex-row md:items-center justify-between px-2 md:px-6 py-2 border-b">
        <div className="flex-1 flex mb-2 md:mb-0 items-center justify-between md:justify-start w-full">
          <div className="flex items-center justify-start">
            <Drawer direction="left" onOpenChange={setDocumentMenuOpen} open={documentMenuOpen}>
              <DrawerTrigger asChild>
                <Button variant="link" onClick={() => setDocumentMenuOpen(!documentMenuOpen)} className="md:hidden">
                  <MenuIcon />
                </Button>
              </DrawerTrigger>
              <DrawerContent className="bg-white flex flex-col h-full max-w-[75%] fixed bottom-0 right-0 border-t-0">
                <div className="p-2 border-b text-sm font-bold">{tokenClaims.resource as string}</div>
                {!isTokenExpired && documentMenu()}
                <div className="flex flex-col items-start mt-1">
                  <Button asChild variant="link">
                    <a href="https://www.verticalinsure.com/support" target="_blank">Contact Support</a>
                  </Button>
                  <Button asChild variant="link">
                    <a href="https://customers.verticalinsure.com" target="_blank">My Account</a>
                  </Button>
                </div>
              </DrawerContent>
            </Drawer>
            <img className="w-40 md:w-48" src="https://brand.verticalinsure.com/assets/201_Vertical%20Insure-identity-02.png" />
          </div>
          
          <div className="block md:hidden">
            {claimsButton()}
          </div>
        </div>
        <div className="space-x-2 justify-start hidden md:flex">
          <Button asChild variant="link">
            <a href="https://www.verticalinsure.com/support" target="_blank">Contact Support</a>
          </Button>
          <Button asChild variant="link">
            <a href="https://customers.verticalinsure.com" target="_blank">My Account</a>
          </Button>
        </div>
        <div className="hidden md:block">
          {claimsButton()}
        </div>
      </nav>
      {isTokenExpired ? (
        <div className="flex flex-1 flex-col items-center w-full md:w-[600px] mt-10 px-8 space-y-8 self-center">
          {newLinkSent ? (
            <p><strong>Success!</strong> We&apos;ve sent a new link to the email you provided.</p>
          ) : (
            <>
              {newLinkError &&
                <Alert variant="destructive">
                  <AlertDescription>Something went wrong on our end. Please try again later, or <a href="mailto:support@verticalinsure.com" className="underline">contact support.</a></AlertDescription>
                </Alert>
              }
              <Alert>
                <AlertTriangle className="w-4 h-4"/>
                <AlertTitle>Expired Link</AlertTitle>
                <AlertDescription>
                  It looks like the link you are using is expired. Enter your email address and we&apos;ll send you a new one to view your policy documents.
                </AlertDescription>
              </Alert>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 w-full">
                  <FormField
                    control={form.control}
                    name="email_address"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email Address</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormDescription>
                          This is the email address you used when purchasing the policy.
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Button type="submit" className="w-full md:w-auto rounded-full" disabled={formLoading}>{formLoading && <Loader2 className="animate-spin h-4 w-4 mr-2" />}Submit</Button>
                </form>
              </Form>
              <span className="w-full border-t" />
              <p className="text-sm">You can also <a className="underline" href="https://customers.verticalinsure.com" target="_blank">click here</a> to log into your account portal and view your policy documents.</p>
            </>
          )} 
        </div> 
      ) : Array.isArray(tokenClaims.resource_location) && tokenClaims.resource_location.length > 1 ? ( 
            <div className="flex-1 flex">
              <div className="border-r flex-col shadow-md hidden md:flex w-64 bg-white">
                <div className="p-2 border-b text-sm font-bold">{tokenClaims.resource as string}</div>
                {documentMenu()}
              </div>
              <div className="flex-1 flex">
                <PDFObject url={Array.isArray(tokenClaims.resource_location) ? tokenClaims.resource_location[selectedDocumentIndex].url : tokenClaims.resource_location as string} containerProps={{style: {flex: 1}}} PDFJS_URL={`/pdfjs/web/viewer.html?file=${tokenClaims.resource_location}`} />
              </div>
            </div>
          ) : (
            <div className="flex-1 flex">
              <PDFObject url={Array.isArray(tokenClaims.resource_location) ? tokenClaims.resource_location[0].url : tokenClaims.resource_location as string} containerProps={{style: {flex: 1}}} PDFJS_URL={`/pdfjs/web/viewer.html?file=${tokenClaims.resource_location}`} />
            </div>
          )
       }
      </>
    )
  } catch {
    return NotFound();
  }
}